import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import {HTMLContent} from '../components/Content'
import MarketingPageTemplate from '../components/MarketingPageTemplate'
import HeroBanner from '../components/Hero'

const MarketingPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <div>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <HeroBanner
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        hero_settings={post.frontmatter.hero_settings}
        cta_title={post.frontmatter.cta_title}
      />
      <MarketingPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </div>
  )
}

MarketingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MarketingPage

export const marketingPageQuery = graphql`
  query MarketingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        meta_title
        meta_description
        hero_settings {
          size
          particle
          images
          css
          font_color
          title_align
        }
      }
    }
  }
`
